import React from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import AnimatedProgressProvider from "../layout/AnimatedProgressProvider"

import AOS from "aos"
AOS.init()

const hero = () => {
  return (
    <div className="v-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              About Me
            </h2>
            <div
              className="space-border"
              data-aos="fade-up"
              data-aos-delay="20"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            ></div>
          </div>
          <div
            className="col-md-8 text-center m-auto"
            data-aos="fade-up"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <p>
              As a full stack developer, I have been working with the Django and
              Angular frameworks for Python and JavaScript since January 2021.
              Throughout my experience, I have gained extensive knowledge in
              both back-end and front-end development, which has enabled me to
              develop robust, scalable, and user-friendly applications.
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-3 p-5 text-center"
            data-aos="fade"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={90}>
              {(value) => {
                const roundedValue = Math.round(value)
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "8px",
                      pathColor: "#cf1f1f",
                      trailColor: "#2f3134",
                    })}
                  />
                )
              }}
            </AnimatedProgressProvider>
            <h4 className="mt-2">PYTHON</h4>
          </div>
          <div
            className="col-lg-3 p-5 text-center"
            data-aos="fade"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={85}>
              {(value) => {
                const roundedValue = Math.round(value)
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "8px",
                      pathColor: "#cf1f1f",
                      trailColor: "#2f3134",
                    })}
                  />
                )
              }}
            </AnimatedProgressProvider>
            <h4 className="mt-2">JAVASCRIPT</h4>
          </div>
          <div
            className="col-lg-3 p-5 text-center"
            data-aos="fade"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={80}>
              {(value) => {
                const roundedValue = Math.round(value)
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "8px",
                      pathColor: "#cf1f1f",
                      trailColor: "#2f3134",
                    })}
                  />
                )
              }}
            </AnimatedProgressProvider>
            <h4 className="mt-2">DJANGO</h4>
          </div>
          <div
            className="col-lg-3 p-5 text-center"
            data-aos="fade"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={75}>
              {(value) => {
                const roundedValue = Math.round(value)
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "8px",
                      pathColor: "#cf1f1f",
                      trailColor: "#2f3134",
                    })}
                  />
                )
              }}
            </AnimatedProgressProvider>
            <h4 className="mt-2">REACT</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default hero
