import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"

// import "swiper/css"

import { Pagination } from "swiper/modules"

import "../styles/portfolio.css"

const GalleryPortfolio = () => {
  return (
    <>
      {/* <div className="container"> */}
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Portfolio</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={10}
        loop={true}
        className="mySwiper container"
      >
        <SwiperSlide>
          <div className="col-12 portfolio_item">
            <div className="portfolio_item_content">
              <div className="d-text">
                <h3>Enterprise resource planning</h3>
              </div>
              <img
                src="./img/gallery/erp.jpg"
                alt="gallery"
                className="col-12"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="col-12 portfolio_item">
            <div className="portfolio_item_content">
              <div className="d-text">
                <h3>Project Management System</h3>
                {/* <div className="col-12">
                  <button className="portfolio_view">
                    <span class="glowing-txt">
                      V<span class="faulty-letter">I</span>EW
                    </span>
                  </button>
                </div> */}
              </div>
              <img
                src="./img/gallery/pmg.jpg"
                alt="gallery"
                className="col-12"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="col-12 portfolio_item">
            <div className="portfolio_item_content">
              <div className="d-text">
                <h3>Enterprise resource planning</h3>
              </div>
              <img
                src="./img/gallery/erp.jpg"
                alt="gallery"
                className="col-12"
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default GalleryPortfolio
