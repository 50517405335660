import React from "react"
import AOS from "aos"
AOS.init()

const whatido = () => {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-12 text-center"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <h2>What I Do</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="spacer-single"></div>
      <div className="row">
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="icon_genius id-color-2"></i>
              <div className="text">
                <h3>Web Designing</h3>
                I'm an experienced web designer with a portfolio of 20+
                projects. My expertise lies in front-end development, UI/UX
                design, and responsive web design. I thrive on creating visually
                appealing and user-centric websites.
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="400"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="icon_tools id-color-2"></i>
              <div className="text">
                <h3>Development</h3>
                Experienced in web development with Django and Python, I've
                completed over 10 successful projects. My focus is on creating
                robust and scalable solutions that leverage the power of these
                technologies to deliver high-quality web applications.
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i class="bi bi-bar-chart-steps"></i>
              <div className="text">
                <h3>Data Science</h3>
                Experienced in data science, I interned at Edureka and completed
                projects that involved data analysis, machine learning, and
                predictive modeling. I'm adept at leveraging data to derive
                actionable insights and develop solutions that drive business
                value.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default whatido
