import { Parallax } from "react-parallax"
import { Link } from "react-scroll"
import Navbar from "../layout/Navbar"
import Hero from "../section/hero"
import About from "../section/about"
import Blockquote from "../section/blockquote"
import Whatido from "../section/whatido"
import Gallery from "../section/gallery"
import Resume from "../section/resume"
import Counter from "../section/counter"
import Blog from "../section/blog"
import Contact from "../section/contact"
import Footer from "../section/footer"
import Preloader from "../layout/Preloader"
import ScrollToTopBtn from "../layout/ScrollToTop"
import { createGlobalStyle } from "styled-components"
import "bootstrap-icons/font/bootstrap-icons.min.css"
import "../styles/home.css"
import GalleryPortfolio from "../custom/GalleryPortfolio"

// const image1 ="./img/background/1.jpg";
const image1 = "./img/background/back3.jpg"

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`

function home() {
  return (
    <>
      <GlobalStyles />
      <Preloader />
      <div className="home">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* HERO */}
        <section id="hero-area" className="bg-bottom py-0">
          <Parallax bgImage={image1} strength={300}>
            <Hero />
            <Link smooth spy to="about">
              <span className="mouse transition" id="fly">
                <span className="scroll"></span>
              </span>
            </Link>
          </Parallax>
        </section>

        {/* ABOUT */}
        <section id="about" className="pb-0">
          <About />
          <Blockquote />
        </section>

        {/* What I DO */}
        <section id="whatido">
          <Whatido />
        </section>

        {/* Gallery */}
        <section id="gallery">
          {/* <Gallery /> */}
          <GalleryPortfolio />
        </section>

        {/* Gallery */}
        <section id="resume" className="pb-0">
          <Resume />
          {/* <Counter/> */}
        </section>

        {/* Gallery */}
        {/* <section id="blog" className="pb-0">
          <Blog />
        </section> */}

        {/* contact */}
        <section id="contact" className="pb-0">
          <Contact />
          <Footer />
        </section>

        <div className="float-text">
          <div className="de_social-icons">
            <a
              href="https://facebook.com/codewithaziz"
              target="_blank"
              className="buton"
            >
              <i className="fa fa-facebook fa-lg"></i>
            </a>
            <a
              href="https://twitter.com/imazizarab"
              target="_blank"
              className="buton"
            >
              <i className="fa fa-twitter fa-lg"></i>
            </a>
            <a
              href="https://instagram.com/codewithaziz"
              target="_blank"
              className="buton"
            >
              <i className="fa fa-instagram fa-lg"></i>
            </a>
          </div>
          <span>Follow Me</span>
        </div>
      </div>
      <ScrollToTopBtn />
    </>
  )
}

export default home
