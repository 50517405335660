import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Resume</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiences</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2020 - 2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Web Designer (Intern)</span>
                                	<span className="d_company">Pixelhub - Afghanistan</span>
                                	I have acquired knowledge in web design and work at Pixelhub, where I specialize in advanced techniques and practices.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2020 - 2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Backend Developer (PHP)</span>
                                	<span className="d_company">Pixelhub</span>
                                	I have acquired knowledge in backend development with a specialization in PHP, and work at Pixelhub, where I focus on advanced techniques and best practices.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2021 - 2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Backend Developer (Django)</span>
                                	<span className="d_company">Milestone - Afghanistan</span>
                                    I specialized in backend development with Django during my tenure at Milestone, leveraging my expertise to deliver robust solutions.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2021 - 2024</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Full-Stack Developer</span>
                                	<span className="d_company">Softlixx - Pakistan</span>
                                    At Softlixx, I specialize in backend (Django), frontend (Angular, React), and desktop (Electron.js) development. Experienced in AWS, SQL, Google APIs, and biometric attendance integrations.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2023 - 2024</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Data Science Intern</span>
                                	<span className="d_company">Edureka</span>
                                    Interned at Edureka, gaining valuable experience in data science.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6" 
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Education</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2020</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Web Designing & PHP & MySql</span>
                                	<span className="d_company">Pixelhub - Afghanistan</span>
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2020</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Mastering JavaScript</span>
                                	<span className="d_company">Code with Mosh</span>
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Python Programming & Django Development</span>
                                	<span className="d_company">Milestone - Afghanistan</span>
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Python Programming & Django Development</span>
                                	<span className="d_company">Code with Mosh</span>
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Front-End (Angular, React)</span>
                                	<span className="d_company">Code with Mosh</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;